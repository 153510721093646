import React, { useState, useEffect } from "react";
import { AppBar, Button, IconButton, Link, Toolbar, Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { ReactComponent as LogoText } from "../assets/svg/logo-text.svg";
import { ReactComponent as LightIcon } from "../assets/svg/sun-solid.svg";
import { ReactComponent as DarkIcon } from "../assets/svg/moon-solid.svg";
import { ReactComponent as MenuIcon } from "../assets/svg/bars-solid.svg";
import { ReactComponent as CloseIcon } from "../assets/svg/times-solid.svg";

import { useSelector, useDispatch } from "react-redux";
import { toggleTheme, getTheme } from "../redux/actions/uiAction";

const style = {
  appBar: {
    boxShadow: "none",
  },
  toolBar: {
    padding: 5,
    display: "block",
  },
  mainToolBarContainer: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
  },
  menuItemContainer: {
    display: {
      xs: "none",
      md: "block",
    },
    marginRight: (theme) => theme.spacing(8),
  },
  menuHamburgerContainer: {
    display: {
      xs: "block",
      md: "none",
    },
  },
  logo: {
    height: 40,
    color: (theme) => theme.palette.text.primary,
    backgroundColor: (theme) => theme.palette.background.default,
    marginLeft: (theme) => ({
      md: theme.spacing(8),
    }),
  },
  menuLink: {
    fontSize: 18,
    marginRight: (theme) => ({
      md: theme.spacing(4),
    }),
    marginBottom: (theme) => ({
      xs: theme.spacing(2),
      md: 0,
    }),
  },
  contactMe: {
    textTransform: "none",
    fontSize: 18,
    borderRadius: 15,
    borderWidth: 2,
    borderColor: (theme) => theme.palette.primary.main,
    "&:hover": {
      backgroundColor: (theme) => theme.palette.primary.main,
      color: (theme) => theme.palette.common.white,
      borderWidth: 2,
    },
    marginRight: (theme) => ({
      md: theme.spacing(4),
    }),
    marginBottom: (theme) => ({
      xs: theme.spacing(2),
      md: 0,
    }),
  },
  themeIcon: {
    color: (theme) => theme.palette.primary.main,
    border: 2,
    borderColor: (theme) => theme.palette.primary.main,
    "&:hover": {
      border: 2,
      backgroundColor: (theme) => theme.palette.primary.main,
      color: (theme) => theme.palette.common.white,
      borderColor: (theme) => theme.palette.primary.main,
    },
  },
  drawerItems: {
    display: {
      xs: "flex",
      md: "none",
    },
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};
export default function Header() {
  const ui = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTheme());
  }, [dispatch]);

  const [state, setstate] = useState({
    showDrawer: false,
  });

  const toggleThemeFunc = () => {
    dispatch(toggleTheme(!ui.isDarkMode));
  };

  const toggleDrawer = () => {
    setstate((prevItem) => ({ showDrawer: !prevItem.showDrawer }));
  };

  return (
    <AppBar position="static" color="transparent" sx={style.appBar}>
      <Toolbar sx={style.toolBar}>
        <Box sx={style.mainToolBarContainer}>
          <Link component={RouterLink} to="/">
            <Box
              sx={style.logo}
              data-aos="zoom-in"
              data-aos-duration="800"
              data-aos-delay="1600"
            >
              <LogoText style={{ height: "inherit", color: "inherit" }} />
            </Box>
          </Link>
          <Box component="span" sx={{ flexGrow: 1 }} />
          <Box sx={style.menuItemContainer}>
            <Link
              href="#"
              underline="none"
              sx={style.menuLink}
              data-aos="fade"
              data-aos-duration="800"
              data-aos-delay="1800"
            >
              Porfolio
            </Link>
            <Button
              variant="outlined"
              color="primary"
              sx={style.contactMe}
              data-aos="fade"
              data-aos-duration="800"
              data-aos-delay="1900"
            >
              Contact Me
            </Button>
            <IconButton
              color="primary"
              sx={style.themeIcon}
              onClick={toggleThemeFunc}
              data-aos="fade"
              data-aos-duration="800"
              data-aos-delay="2000"
            >
              {ui.isDarkMode ? (
                <LightIcon style={{ height: 20, color: "inherit" }} />
              ) : (
                <DarkIcon style={{ height: 20, color: "inherit" }} />
              )}
            </IconButton>
          </Box>
          <Box sx={style.menuHamburgerContainer}>
            <IconButton color="primary" onClick={toggleDrawer}>
              {state.showDrawer ? (
                <CloseIcon style={{ width: 20, color: "inherit" }} />
              ) : (
                <MenuIcon style={{ width: 20, color: "inherit" }} />
              )}
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            ...style.drawerItems,
            display: { xs: state.showDrawer ? "flex" : "none", md: "none" },
          }}
        >
          <Link href="#" underline="none" sx={style.menuLink}>
            Porfolio
          </Link>
          <Button variant="outlined" color="primary" sx={style.contactMe}>
            Contact Me
          </Button>
          <IconButton
            color="primary"
            sx={style.themeIcon}
            onClick={toggleThemeFunc}
          >
            {ui.isDarkMode ? (
              <LightIcon style={{ height: 20, color: "inherit" }} />
            ) : (
              <DarkIcon style={{ height: 20, color: "inherit" }} />
            )}
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
